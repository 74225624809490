.main-content {
  background: $color-background;
  height: 100vh;
  position: relative;

  &-area {
    min-height: calc( 100% - #{$app-header-height} - 160px); }

  @include container;
  padding-top: $app-header-height;

  @include media-breakpoint-down(lg) {
    padding-top: $app-header-height * 1.75; } }
