.social-links {
  display: flex;
  flex-wrap: wrap;
  list-style-type: none;
  margin: $m-base auto;
  max-width: 1500px;
  padding: 0;

  li {
    display: flex;
    flex-basis: 33%;
    flex-grow: 1;

    a {
      margin: auto; } }

  &__link {
    align-items: center;
    display: flex;

    svg {
      height: 16px;
      margin: 14px;
      width: 22px;

      path {
        fill: $color-gray;
        @include transition(fill); } }

    &:hover {
      &.-linkedin {
        svg {
          path {
            fill: $color-icon-linkedin; } } }

      &.-medium {
        svg {
          path {
            fill: $color-icon-medium; } } }

      &.-youtube {
        svg {
          path {
            fill: $color-icon-youtube; } } }

      &.-behance {
        svg {
          path {
            fill: $color-icon-behance; } } }

      &.-dribbble {
        svg {
          path {
            fill: $color-icon-dribbble; } } }

      &.-twitter {
        svg {
          path {
            fill: $color-icon-twitter; } } }

      &.-instagram {
        svg {
          path {
            fill: $color-icon-instagram; } } } } }

  @include media-breakpoint-up(md) {
    li {
      flex-basis: auto; } } }
