$hamburger-vertical-center-value: 71px;

.side-nav {
  background: $color-black;
  display: flex;
  flex-direction: column;
  height: 100vh;
  position: fixed;
  left: 0;
  width: $side-nav-width;
  z-index: $z-higher;

  visibility: hidden;
  transform: translateX(-50%);
  opacity: 0;

  @include transition(opacity,transform,visibility);

  &__hamburger {
    position: fixed;
    width: 34px;
    height: 26px;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    left: $grid-gutter-width;
    top: $hamburger-vertical-center-value;

    z-index: $z-higher + 10;

    @include transition(left);
    opacity: 1;

    .bar {
      width: 100%;
      height: 3px;
      border-radius: 1px;
      background-color: #FFFEFC;
      @include transition(transform); } }

  &.-active {
    visibility: visible;
    transform: translateX(0);
    opacity: 1;

    & + .side-nav__hamburger {
      left: $side-nav-width - 64px; } }

  @include media-breakpoint-down(lg) {
    .side-nav__menu {
      margin-top: 38px;
      .search-box {
        &__input {
          width: 190px; }
        &__icon {
          right: 50px; } } } }

  @include media-breakpoint-up(xl) {
    visibility: visible;
    transform: translateX(0);
    opacity: 1;

    &__hamburger {
      display: none; } }

  &__menu {
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    list-style: none;
    margin-top: 11px;
    margin-bottom: 64px;
    position: relative;
    width: 100%; }

  &__menu-item {
    font-size: 14px;
    font-weight: 600;
    letter-spacing: 0.28px;
    width: 100%;
    margin-top: 21px; } }
