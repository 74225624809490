.chevron {
  border-radius: 50%;
  height: $m-base;
  margin-left: $m-base;
  margin-right: 0;
  transition: all 0.3s ease-in;
  width: $m-base;

  &.-rotate {
    transform: rotate(180deg); } }

.add-post-btn {
  background-color: $color-primary;
  border-radius: $border-radius-sm;
  color: $color-dark;
  display: inline-block;
  font-size: $font-size-xs;
  font-weight: $font-weight-600;
  height: $m-xs * 5;
  padding: $m-xs;
  text-align: center;
  text-decoration: none;
  width: $m-sm * 10;
  @include transition(background-color);

  &:hover {
    background-color: $color-primary-hover;
    color: $color-dark; } }

.delete-post-btn {
  background-color: $color-primary-red;
  border-radius: $border-radius-sm;
  color: $color-white;
  display: inline-block;
  font-size: $font-size-m;
  font-weight: $font-weight-600;
  height: $m-xs * 5;
  padding: $m-xs;
  text-align: center;
  text-decoration: none;
  width: $m-sm * 10;
  @include transition(background-color);

  &:hover {
    background-color: $color-red-hover; } }

.edit-post-btn {
  background-color: transparent;
  border: 2px solid $color-primary-blue;
  border-radius: $border-radius-sm;
  color: $color-primary-blue;
  display: inline-block;
  font-size: $font-size-m;
  font-weight: $font-weight-600;
  height: $m-xs * 5;
  padding: $m-xs;
  text-align: center;
  text-decoration: none;
  width: $m-sm * 10;
  @include transition(background-color);

  &:hover {
    background-color: rgba($color-primary-blue, .1); } }

.cancel-button {
  background-color: transparent;
  border-radius: $border-radius-sm;
  border: 2px solid $color-gray;
  color: $color-gray;
  display: inline-block;
  font-size: $font-size-m;
  font-weight: $font-weight-600;
  height: $m-xs * 5;
  margin-right: $m-lg;
  padding: $m-xs;
  text-align: center;
  text-decoration: none;
  width: $m-sm * 10;
  @include transition(border-color, color);

  &:hover {
    border-color: $color-white;
    color: $color-white; } }

.profile-link {
  color: white;
  text-decoration: none;

  svg {
    height: $font-size-m;
    width: $font-size-m; }

  &__text {
    font-size: $font-size-m; } }
