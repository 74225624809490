.tippy-popper {

  .tippy-tooltip {
    background: #969696;
    margin: auto;
    min-width: 100px;
    width: 100%;

    a, div {
      text-decoration: none;
      color: $color-white;
      font-weight: 500;

      &::first-letter {
        text-transform: uppercase; } } } }

.ToolTip {
  display: block !important;

  &__link {
    text-decoration: none;
    display: block;

    .post__single-category {
      transition: background $transition-duration $transition-function;

      &:hover {
        background: rgba(#A0A0A0, 0.1 ); } } } }
