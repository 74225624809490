.app-header {
  background: $color-background;

  position: fixed;
  top: 0;
  left: $grid-gutter-width;
  right: $grid-gutter-width;

  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;

  height: $app-header-height;
  max-height: $app-header-height;

  z-index: $z-high;

  .logo__link {
    flex: 100%;
    text-align: left;
    margin: 0px auto 15px; }

  @include media-breakpoint-down(lg) {
    height: unset;
    max-height: unset;
    padding: 10px 0px; }

  @include media-breakpoint-up(md) {
    justify-content: flex-end; }

  @include media-breakpoint-up(sm) {
    .logo__link {
      text-align: center; } }

  @include media-breakpoint-up(xl) {
    right: 24px;

    .logo__link {
      display: none; } } }
