.review-post {
  &__container {}
  &__confirm-button {
    background-color: $color-primary;
    border-radius: 10px;
    color: #1f1f1f;
    display: block;
    font-size: 12px;
    font-weight: 600;
    height: 40px;
    margin-left: auto;
    margin-right: 40px;
    padding: 8px;
    text-align: center;
    text-decoration: none;
    width: 160px; } }
