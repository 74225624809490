.categories {
  overflow: auto;

  &__single-category {
    @include transition(background-color);
    align-items: center;
    color: $color-white;
    cursor: pointer;
    display: flex;
    padding: $m-sm 0 $m-sm $m-lg;
    text-decoration: none;
    width: 100%;

    svg {

      path {
        fill: $color-white; } }

    &:hover, &.-active {
      background-color: rgba($color-primary, .1);

      svg {
        fill: $color-primary;

        path {
          fill: $color-primary;
          @include transition(fill); } }

      .categories__name {
        @include transition(color);
        color: $color-primary; } }

    &.-color-only-stroke:hover, &.-color-only-stroke.-active {

      svg {
        fill: transparent;
        stroke: $color-primary;
        @include transition(stroke); } } }


  &__icon {
    display: flex;
    height: $m-sm;
    margin-right: $m-xs;
    width: auto; }

  &__name {
    display: flex;
    font-size: $font-size-sm;
    font-weight: $font-weight-600;
    text-transform: uppercase; }

  &::-webkit-scrollbar {
    width: 3px; }

  &::-webkit-scrollbar-thumb {
    background-color: $color-gray;
    outline: 1px solid slategrey; }

  &::-webkit-scrollbar-track-piece:end {
    background: transparent;
    margin-bottom: $m-xs; } }
