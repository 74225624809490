.add-post-container {
  display: flex;
  flex-direction: column;

  @include media-breakpoint-up(lg) {
    flex-direction: row; } }

.add-post {

  &__header {
    color: $color-white;
    font-size: $font-size-xs * 2;
    font-weight: $font-weight-600;
    margin-bottom: $m-base; }

  &__title {
    @include transition(border-color);
    background: $color-background;
    border-radius: $border-radius-sm;
    border: 1px solid $color-gray;
    caret-color: $color-gray;
    color: $color-gray;
    font-size: $font-size-m;
    font-weight: $font-weight-light;
    height: $m-lg;
    margin: $m-xs 0 $m-sm;
    opacity: 1;
    outline: none;
    padding-left: $m-sm;
    width: 100%;

    &::placeholder {
      color: $color-gray;
      font-family: Poppins; }

    &:not(:placeholder-shown) {
      border-color: $color-white;
      color: $color-white; }

    &:focus,
    &:active {
      border-color: $color-primary; } }

  &__post-create {
    flex-basis: 45%; } }

.form-label {
  font-size: $font-size-m;
  font-weight: $font-weight-bold; }

