//----- fonts sizes and line-heights -----------------------------------------------------------------------------------
$font-size-xs:                    12px;
$font-size-sm:                    14px;
$font-size-m:                     16px;
$font-size-base:                  18px;
$font-size-lg:                    22px;
$font-size-xl:                    28px;
$font-size-2xl:                   48px;
$font-size-3xl:                   72px;

$font-size-post-title:            32px;

//----- spacings (margins/paddings) ------------------------------------------------------------------------------------
$m-xs:                            8px;
$m-sm:                            $m-xs * 2;
$m-base:                          $m-xs * 3;
$m-lg:                            $m-base * 2;
$m-xl:                            $m-base * 3;
$m-2xl:                           $m-base * 4;

$side-nav-width:                  320px;
$app-header-height:               82px;

//----- line heights ---------------------------------------------------------------------------------------------------
$line-height-sm:                  $m-sm;
$line-height-base:                $m-base;
$line-height-lg:                  $m-lg;
$text-area-height:                200px;

//----- border radiuses ------------------------------------------------------------------------------------------------
$border-radius-base:              $m-base;
$border-radius-sm:                $m-xs;
$border-radius-lg:                $m-lg;

//----- breakpoints ----------------------------------------------------------------------------------------------------
$grid-breakpoints:                (xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px);

$grid-columns:                    12;
$grid-gutter-width:               $m-base;

$container:                       map-get($grid-breakpoints, xl);

//----- colors ---------------------------------------------------------------------------------------------------------
$color-primary:                   #FEC92D;
$color-primary-red:               #FD413C;
$color-primary-blue:              #1891F8;
$color-secondary:                 #48bd66;
$color-success:                   #48bd66;
$color-warning:                   #ffd200;
$color-danger:                    #e62a2a;
$color-info:                      #5bc0de;

$color-primary-hover:             #E0B228;
$color-red-hover:                 #C93530;

$color-white:                     #FFFEFC;
$color-gray:                      #A0A0A0;
$color-gray-300:                  hsl(36, 5%, 85%);
$color-gray-400:                  hsl(36, 5%, 75%);
$color-gray-500:                  hsl(36, 5%, 62%);
$color-gray-600:                  hsl(36, 5%, 38%);
$color-gray-700:                  hsl(36, 5%, 28%);
$color-black:                     #212529;
$color-dark:                      #1C1F22;
$color-background:                #2A2E32;

$color-icon-like:                 #1769ff;
$color-icon-funny:                #FFC074;
$color-icon-love:                 #ff0000;
$color-icon-surprised:            #E99497;
$color-icon-linkedin:             #0077b5;
$color-icon-medium:               #1c9963;
$color-icon-youtube:              #ff0000;
$color-icon-behance:              #1769ff;
$color-icon-dribbble:             #ea4c89;
$color-icon-twitter:              #1da1f2;
$color-icon-instagram:            #e1306c;

$color-code-markdown:             #ffc66d;
$color-code-background:           #2b2b2b;

//----- fonts ----------------------------------------------------------------------------------------------------------
$font-family-header:              'Rubik';
$font-family-body:                'Nunito Sans';
$font-stack-header:               $font-family-header, sans-serif;
$font-stack-body:                 $font-family-body, serif;

$font-weight-light:               300;
$font-weight-regular:             400;
$font-weight-500:                 500;
$font-weight-600:                 600;
$font-weight-bold:                700;

$icon-size:                       $m-base;

//----- transitions ----------------------------------------------------------------------------------------------------
$transition-duration:             .3s;
$transition-function:              ease-in-out;

//----- z-indexes ------------------------------------------------------------------------------------------------------
$z-negative:                      -1;
$z-lower:                         30;
$z-low:                           40;
$z-base:                          50;
$z-high:                          60;
$z-higher:                        70;


$dark-background: #343434 0% 0% no-repeat padding-box;
$font-family: Poppins;
$font-family-code-markdown: Consolas, Monaco, "Andale Mono", monospace;
$font-family-default: sans-serif;
