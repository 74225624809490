.posts-list-wrapper {

    &__header {
        margin: {
            top: $m-sm;
            bottom: $m-lg; }

        @include media-breakpoint-only(xs) {
            font-size: $font-size-xl;
            margin: {
                bottom: $m-base; } } } }

