.login-link {
  align-items: center;
  color: white;
  display: flex;
  font-weight: 900;
  letter-spacing: 1px;
  padding: 32px;
  text-decoration: none;
  text-transform: uppercase; }


