.delete-modal,
.action-modal {
  .ReactModal__Content {
    max-width: 600px !important; }
  &__content {
    padding: 50px 2.5%;
    background-color: #272B2F, }

  &__header {
    text-align: center;
    margin-bottom: $font-size-2xl;
    font-size: 24px;
    letter-spacing: 0.48px;
    font-weight: 600;
    line-height: normal; }

  &__buttons {
    display: flex;
    justify-content: center;

    .cancel-button {
      margin-right: 5%; } } }
