@font-face {
  font-family: 'devicon';
  src:  url('assets/fonts/devicon_fonts/devicon.eot?yl3aib');
  src:  url('assets/fonts/devicon_fonts/devicon.eot?yl3aib#iefix') format('embedded-opentype'),
    url('assets/fonts/devicon_fonts/devicon.ttf?yl3aib') format('truetype'),
    url('assets/fonts/devicon_fonts/devicon.woff?yl3aib') format('woff'),
    url('assets/fonts/devicon_fonts/devicon.svg?yl3aib#devicon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="devicon-"], [class*=" devicon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'devicon' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.devicon-haskell-plain:before {
  content: "\e95f";
}
.devicon-haskell-plain-wordmark:before {
  content: "\e960";
}
.devicon-codepen-original-wordmark:before {
  content: "\e95d";
}
.devicon-codepen-plain:before {
  content: "\e95e";
}
.devicon-groovy-plain:before {
  content: "\e958";
}
.devicon-rust-plain:before {
  content: "\e959";
}
.devicon-scala-plain-wordmark:before {
  content: "\e95a";
}
.devicon-scala-plain:before {
  content: "\e95b";
}
.devicon-grails-plain:before {
  content: "\e95c";
}
.devicon-sketch-line-wordmark:before {
  content: "\e94c";
}
.devicon-sketch-line:before {
  content: "\e94d";
}
.devicon-npm-original-wordmark:before {
  content: "\e952";
}
.devicon-ionic-original-wordmark:before {
  content: "\e953";
}
.devicon-ionic-original:before {
  content: "\e954";
}
.devicon-ember-plain:before {
  content: "\e955";
}
.devicon-electron-original-wordmark:before {
  content: "\e956";
}
.devicon-electron-original:before {
  content: "\e957";
}
.devicon-vagrant-plain-wordmark:before {
  content: "\e94e";
}
.devicon-vagrant-plain:before {
  content: "\e94f";
}
.devicon-yarn-plain-wordmark:before {
  content: "\e950";
}
.devicon-yarn-plain:before {
  content: "\e951";
}
.devicon-handlebars-plain-wordmark:before {
  content: "\e94a";
}
.devicon-handlebars-plain:before {
  content: "\e94b";
}
.devicon-couchdb-plain-wordmark:before {
  content: "\e948";
}
.devicon-couchdb-plain:before {
  content: "\e949";
}
.devicon-behance-plain-wordmark:before {
  content: "\e943";
}
.devicon-behance-plain:before {
  content: "\e945";
}
.devicon-linkedin-plain-wordmark:before {
  content: "\e946";
}
.devicon-linkedin-plain:before {
  content: "\e947";
}
.devicon-ceylon-plain:before {
  content: "\e944";
}
.devicon-elm-plain-wordmark:before {
  content: "\e941";
}
.devicon-elm-plain:before {
  content: "\e942";
}
.devicon-cakephp-plain-wordmark:before {
  content: "\e93f";
}
.devicon-cakephp-plain:before {
  content: "\e940";
}
.devicon-stylus-original:before {
  content: "\e93e";
}
.devicon-express-original-wordmark:before {
  content: "\e93c";
}
.devicon-express-original:before {
  content: "\e93d";
}
.devicon-devicon-plain-wordmark:before {
  content: "\e93a";
}
.devicon-devicon-plain:before {
  content: "\e93b";
}
.devicon-intellij-plain-wordmark:before {
  content: "\e932";
}
.devicon-intellij-plain:before {
  content: "\e933";
}
.devicon-pycharm-plain-wordmark:before {
  content: "\e934";
}
.devicon-pycharm-plain:before {
  content: "\e935";
}
.devicon-rubymine-plain-wordmark:before {
  content: "\e936";
}
.devicon-rubymine-plain:before {
  content: "\e937";
}
.devicon-webstorm-plain-wordmark:before {
  content: "\e938";
}
.devicon-webstorm-plain:before {
  content: "\e939";
}
.devicon-tomcat-line-wordmark:before {
  content: "\e92c";
}
.devicon-tomcat-line:before {
  content: "\e92d";
}
.devicon-vuejs-line-wordmark:before {
  content: "\e92e";
}
.devicon-vuejs-line:before {
  content: "\e92f";
}
.devicon-vuejs-plain-wordmark:before {
  content: "\e930";
}
.devicon-vuejs-plain:before {
  content: "\e931";
}
.devicon-swift-plain-wordmark:before {
  content: "\e92a";
}
.devicon-swift-plain:before {
  content: "\e92b";
}
.devicon-webpack-plain-wordmark:before {
  content: "\e928";
}
.devicon-webpack-plain:before {
  content: "\e929";
}
.devicon-visualstudio-plain-wordmark:before {
  content: "\e926";
}
.devicon-visualstudio-plain:before {
  content: "\e927";
}
.devicon-slack-plain-wordmark:before {
  content: "\e924";
}
.devicon-slack-plain:before {
  content: "\e925";
}
.devicon-sequelize-plain-wordmark:before {
  content: "\e922";
}
.devicon-sequelize-plain:before {
  content: "\e923";
}
.devicon-typescript-plain:before {
  content: "\e920";
}
.devicon-babel-plain:before {
  content: "\e921";
}
.devicon-facebook-plain:before {
  content: "\e91c";
}
.devicon-google-plain-wordmark:before {
  content: "\e91d";
}
.devicon-google-plain:before {
  content: "\e91e";
}
.devicon-twitter-original:before {
  content: "\e91f";
}
.devicon-mocha:before {
  content: "\e919";
}
.devicon-jasmine-plain:before {
  content: "\e91a";
}
.devicon-jasmine-wordmark:before {
  content: "\e91b";
}
.devicon-gatling-plain:before {
  content: "\e917";
}
.devicon-gatling-plain-wordmark:before {
  content: "\e918";
}
.devicon-phpstorm-plain:before {
  content: "\e911";
}
.devicon-phpstorm-plain-wordmark:before {
  content: "\e912";
}
.devicon-sourcetree-original:before {
  content: "\e913";
}
.devicon-sourcetree-original-wordmark:before {
  content: "\e914";
}
.devicon-ssh-original:before {
  content: "\e915";
}
.devicon-ssh-original-wordmark:before {
  content: "\e916";
}
.devicon-jeet-plain:before {
  content: "\e903";
}
.devicon-jeet-plain-wordmark:before {
  content: "\e906";
}
.devicon-gitlab-plain:before {
  content: "\e907";
}
.devicon-gitlab-plain-wordmark:before {
  content: "\e908";
}
.devicon-github-original:before {
  content: "\e909";
}
.devicon-github-original-wordmark:before {
  content: "\e90a";
}
.devicon-d3js-plain:before {
  content: "\e90b";
}
.devicon-d3js-original:before {
  content: "\e90c";
}
.devicon-confluence-original:before {
  content: "\e90d";
}
.devicon-confluence-original-wordmark:before {
  content: "\e90e";
}
.devicon-bitbucket-original:before {
  content: "\e90f";
}
.devicon-bitbucket-original-wordmark:before {
  content: "\e910";
}
.devicon-gradle-plain:before {
  content: "\e902";
}
.devicon-gradle-plain-wordmark:before {
  content: "\e8f1";
}
.devicon-cucumber-plain:before {
  content: "\e904";
}
.devicon-cucumber-plain-wordmark:before {
  content: "\e905";
}
.devicon-protractor-plain:before {
  content: "\e900";
}
.devicon-protractor-plain-wordmark:before {
  content: "\e901";
}
.devicon-safari-line-wordmark:before {
  content: "\e632";
}
.devicon-safari-line:before {
  content: "\e63a";
}
.devicon-safari-plain-wordmark:before {
  content: "\e63b";
}
.devicon-safari-plain:before {
  content: "\e63c";
}
.devicon-jetbrains-plain:before {
  content: "\e63d";
}
.devicon-django-line:before {
  content: "\e63e";
}
.devicon-django-plain:before {
  content: "\e63f";
}
.devicon-gimp-plain:before {
  content: "\e633";
}
.devicon-redhat-plain-wordmark:before {
  content: "\e62a";
}
.devicon-redhat-plain:before {
  content: "\e62b";
}
.devicon-cplusplus-line:before {
  content: "\e634";
}
.devicon-cplusplus-plain:before {
  content: "\e635";
}
.devicon-csharp-line:before {
  content: "\e636";
}
.devicon-csharp-plain:before {
  content: "\e637";
}
.devicon-c-line:before {
  content: "\e638";
}
.devicon-c-plain:before {
  content: "\e639";
}
.devicon-nodewebkit-line-wordmark:before {
  content: "\e611";
}
.devicon-nodewebkit-line:before {
  content: "\e612";
}
.devicon-nodewebkit-plain-wordmark:before {
  content: "\e613";
}
.devicon-nodewebkit-plain:before {
  content: "\e614";
}
.devicon-nginx-original:before {
  content: "\e615";
}
.devicon-erlang-plain-wordmark:before {
  content: "\e616";
}
.devicon-erlang-plain:before {
  content: "\e617";
}
.devicon-doctrine-line-wordmark:before {
  content: "\e618";
}
.devicon-doctrine-line:before {
  content: "\e619";
}
.devicon-doctrine-plain-wordmark:before {
  content: "\e61a";
}
.devicon-doctrine-plain:before {
  content: "\e625";
}
.devicon-apache-line-wordmark:before {
  content: "\e626";
}
.devicon-apache-line:before {
  content: "\e627";
}
.devicon-apache-plain-wordmark:before {
  content: "\e628";
}
.devicon-apache-plain:before {
  content: "\e629";
}
.devicon-go-line:before {
  content: "\e610";
}
.devicon-redis-plain-wordmark:before {
  content: "\e606";
}
.devicon-redis-plain:before {
  content: "\e607";
}
.devicon-meteor-plain-wordmark:before {
  content: "\e608";
}
.devicon-meteor-plain:before {
  content: "\e609";
}
.devicon-heroku-line-wordmark:before {
  content: "\e60a";
}
.devicon-heroku-line:before {
  content: "\e60b";
}
.devicon-heroku-plain-wordmark:before {
  content: "\e60c";
}
.devicon-heroku-plain:before {
  content: "\e60f";
}
.devicon-go-plain:before {
  content: "\e61b";
}
.devicon-docker-plain-wordmark:before {
  content: "\e61e";
}
.devicon-docker-plain:before {
  content: "\e61f";
}
.devicon-symfony-original-wordmark:before {
  content: "\e602";
}
.devicon-symfony-original:before {
  content: "\e605";
}
.devicon-react-original-wordmark:before {
  content: "\e600";
}
.devicon-react-plain:before {
  content: "\e601";
}
.devicon-amazonwebservices-original:before {
  content: "\e603";
}
.devicon-aws-plain-wordmark:before {
  content: "\e604";
}
.devicon-android-plain-wordmark:before {
  content: "\e60d";
}
.devicon-android-plain:before {
  content: "\e60e";
}
.devicon-angularjs-plain-wordmark:before {
  content: "\e61c";
}
.devicon-angular-plain:before {
  content: "\e61d";
}
.devicon-appcelerator-original:before {
  content: "\e620";
}
.devicon-appcelerator-plain-wordmark:before {
  content: "\e621";
}
.devicon-osx-plain:before {
  content: "\e622";
}
.devicon-atom-original-wordmark:before {
  content: "\e623";
}
.devicon-atom-original:before {
  content: "\e624";
}
.devicon-backbonejs-plain-wordmark:before {
  content: "\e62c";
}
.devicon-backbonejs-plain:before {
  content: "\e62d";
}
.devicon-bootstrap-plain-wordmark:before {
  content: "\e62e";
}
.devicon-bootstrap-plain:before {
  content: "\e62f";
}
.devicon-bower-line-wordmark:before {
  content: "\e630";
}
.devicon-bower-line:before {
  content: "\e631";
}
.devicon-bower-plain-wordmark:before {
  content: "\e64e";
}
.devicon-bower-plain:before {
  content: "\e64f";
}
.devicon-chrome-plain-wordmark:before {
  content: "\e665";
}
.devicon-chrome-plain:before {
  content: "\e666";
}
.devicon-codeigniter-plain-wordmark:before {
  content: "\e667";
}
.devicon-codeigniter-plain:before {
  content: "\e668";
}
.devicon-coffeescript-original-wordmark:before {
  content: "\e669";
}
.devicon-coffeescript-original:before {
  content: "\e66a";
}
.devicon-css3-plain-wordmark:before {
  content: "\e678";
}
.devicon-css-plain:before {
  content: "\e679";
}
.devicon-debian-plain-wordmark:before {
  content: "\e67e";
}
.devicon-debian-plain:before {
  content: "\e67f";
}
.devicon-dot-net-plain-wordmark:before {
  content: "\e6d3";
}
.devicon-dot-net-plain:before {
  content: "\e6d4";
}
.devicon-drupal-plain-wordmark:before {
  content: "\e6e2";
}
.devicon-drupal-plain:before {
  content: "\e6e3";
}
.devicon-firefox-plain-wordmark:before {
  content: "\e75d";
}
.devicon-firefox-plain:before {
  content: "\e75e";
}
.devicon-foundation-plain-wordmark:before {
  content: "\e7a2";
}
.devicon-foundation-plain:before {
  content: "\e7a3";
}
.devicon-git-plain-wordmark:before {
  content: "\e7a7";
}
.devicon-git-plain:before {
  content: "\e7a8";
}
.devicon-grunt-line-wordmark:before {
  content: "\e7a9";
}
.devicon-grunt-line:before {
  content: "\e7aa";
}
.devicon-grunt-plain-wordmark:before {
  content: "\e7ea";
}
.devicon-grunt-plain:before {
  content: "\e7eb";
}
.devicon-gulp-plain:before {
  content: "\e7ec";
}
.devicon-html5-plain-wordmark:before {
  content: "\e7f6";
}
.devicon-html-plain:before {
  content: "\e7f7";
}
.devicon-ie10-original:before {
  content: "\e7f8";
}
.devicon-illustrator-line:before {
  content: "\e7f9";
}
.devicon-illustrator-plain:before {
  content: "\e7fa";
}
.devicon-inkscape-plain-wordmark:before {
  content: "\e834";
}
.devicon-inkscape-plain:before {
  content: "\e835";
}
.devicon-java-plain-wordmark:before {
  content: "\e841";
}
.devicon-java-plain:before {
  content: "\e842";
}
.devicon-javascript-plain:before {
  content: "\e845";
}
.devicon-jquery-plain-wordmark:before {
  content: "\e849";
}
.devicon-jquery-plain:before {
  content: "\e84a";
}
.devicon-krakenjs-plain-wordmark:before {
  content: "\e84f";
}
.devicon-krakenjs-plain:before {
  content: "\e850";
}
.devicon-laravel-plain-wordmark:before {
  content: "\e851";
}
.devicon-laravel-plain:before {
  content: "\e852";
}
.devicon-less-plain-wordmark:before {
  content: "\e853";
}
.devicon-linux-plain:before {
  content: "\eb1c";
}
.devicon-mongodb-plain-wordmark:before {
  content: "\eb43";
}
.devicon-mongodb-plain:before {
  content: "\eb44";
}
.devicon-moodle-plain-wordmark:before {
  content: "\eb5a";
}
.devicon-moodle-plain:before {
  content: "\eb5b";
}
.devicon-mysql-plain-wordmark:before {
  content: "\eb60";
}
.devicon-mysql-plain:before {
  content: "\eb61";
}
.devicon-nodejs-plain-wordmark:before {
  content: "\eb69";
}
.devicon-nodejs-plain:before {
  content: "\eb6a";
}
.devicon-oracle-original:before {
  content: "\eb6b";
}
.devicon-photoshop-line:before {
  content: "\eb6c";
}
.devicon-photoshop-plain:before {
  content: "\eb6d";
}
.devicon-php-plain:before {
  content: "\eb71";
}
.devicon-postgresql-plain-wordmark:before {
  content: "\eb7c";
}
.devicon-postgresql-plain:before {
  content: "\eb7d";
}
.devicon-python-plain-wordmark:before {
  content: "\eb88";
}
.devicon-python-plain:before {
  content: "\eb89";
}
.devicon-rails-plain-wordmark:before {
  content: "\eba2";
}
.devicon-rails-plain:before {
  content: "\eba3";
}
.devicon-ruby-plain-wordmark:before {
  content: "\ebc9";
}
.devicon-ruby-plain:before {
  content: "\ebca";
}
.devicon-sass-original:before {
  content: "\ebcb";
}
.devicon-travis-plain-wordmark:before {
  content: "\ebcc";
}
.devicon-travis-plain:before {
  content: "\ebcd";
}
.devicon-trello-plain-wordmark:before {
  content: "\ebce";
}
.devicon-trello-plain:before {
  content: "\ebcf";
}
.devicon-ubuntu-plain-wordmark:before {
  content: "\ebd0";
}
.devicon-ubuntu-plain:before {
  content: "\ebd1";
}
.devicon-vim-plain:before {
  content: "\ebf3";
}
.devicon-windows8-original-wordmark:before {
  content: "\ebf4";
}
.devicon-windows8-original:before {
  content: "\ebf5";
}
.devicon-wordpress-plain-wordmark:before {
  content: "\ebfd";
}
.devicon-wordpress-plain:before {
  content: "\ebfe";
}
.devicon-yii-plain-wordmark:before {
  content: "\ec01";
}
.devicon-yii-plain:before {
  content: "\ec02";
}
.devicon-zend-plain-wordmark:before {
  content: "\ec03";
}
.devicon-zend-plain:before {
  content: "\ec04";
}
