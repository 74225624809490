.check-if-can-review-container {
  width: 100%;
  height: 50vh;
  min-height: 300px;

  display: flex;
  justify-content: center;
  align-items: center;

  .log-in-btn {
    @extend .add-post-btn; } }
