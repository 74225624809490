.navigator-button {
    cursor: pointer;
    margin: {
        right: auto; }
    visibility: hidden;

    &__link {
        align-items: center;
        color: $color-white;
        display: flex;
        font-size: $font-size-sm;
        text-decoration: none;
        @include transition(color);

        &:hover {
            color: $color-primary; } }

    &__text {
        margin-left: $m-xs; }

    @include media-breakpoint-up(xl) {
        padding-left: $side-nav-width;
        visibility: visible; } }
