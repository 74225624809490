.opacity-animation {
  &-enter,
  &-appear {
    opacity: 0;
    &-active,
    &-done {
      opacity: 1;
      transition: all $transition-duration $transition-function; } }

  &-exit {
    opacity: 1;
    &-active,
    &-done {
      opacity: 0;
      transition: all $transition-duration $transition-function; } } }

.post-animation {
  &-enter,
  &-appear {
    opacity: 0;
    transform: translateY($m-base);
    &-active,
    &-done {
      opacity: 1;
      transform: translateY(0);
      transition: all $transition-duration $transition-function; } }

  &-exit {
    opacity: 1;
    transform: translateY(0);
    &-active,
    &-done {
      opacity: 0;
      transform: translateY($m-base);
      transition: all $transition-duration $transition-function; } } }

.search-animation {
  &-enter,
  &-appear {
    opacity: 0;
    transform: translateX(-$m-2xl);
    &-active,
    &-done {
      opacity: 1;
      transform: translateX(0);
      transition: all $transition-duration $transition-function; } }

  &-exit {
    opacity: 1;
    transform: translateX(0);
    &-active,
    &-done {
      opacity: 0;
      transform: translateX(-$m-2xl);
      transition: all $transition-duration $transition-function; } } }

.zoom-animation {
  &-enter,
  &-appear {
    opacity: 0;
    transform: scale(0.85);
    &-active,
    &-done {
      opacity: 1;
      transform: scale(1);
      transition: all $transition-duration $transition-function; } }

  &-exit {
    opacity: 1;
    transform: scale(1);
    &-active,
    &-done {
      opacity: 0;
      transform: scale(0.85);
      transition: all $transition-duration $transition-function !important; } } }
