* {
  box-sizing: border-box; }

body {
  color: $color-white;
  font: {
    family: $font-stack-body;
    size: $font-size-base; }
  line-height: $line-height-base; }

b,
strong {
  font-weight: $font-weight-bold; }

a,
button,
input[type='button'],
input[type='submit'] {
  cursor: pointer;

  &:focus {
    box-shadow: none;
    outline: none; } }

a {
  color: $color-primary;

  @include hover {
    color: $color-primary; } }

img {
  display: block;
  max-width: 100%; }

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $font-stack-header;
  margin: {
    bottom: $m-base;
    top: 0; } }

p {
  margin: {
    bottom: $m-sm;
    top: 0; } }

button {
  appearance: none;
  background-color: transparent;
  border: none;
  outline: none;
  padding: 0; }

textarea {
  resize: vertical; }
