$user-panel-radius: 5px;

.user-panel-container {
  background-color: $color-background;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex: 1;
  margin-right: -$m-sm;

  .add-post-btn {
    margin-left: auto;
    margin-right: 18px; }

  @include media-breakpoint-up(md) {
    justify-content: flex-end;
    max-width: max-content; }

  @include media-breakpoint-down(lg) {
    padding-left: $grid-gutter-width * 2; } }

.user-panel {
  border-top-left-radius: $user-panel-radius;
  border-top-right-radius: $user-panel-radius;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  letter-spacing: 0.28px;
  padding: $m-sm;

  position: relative;
  cursor: pointer;
  transition-duration: $transition-duration;

  &__menu {
    background-color: $color-black;
    box-shadow: 0px 3px 10px #1F1F1F;
    border-bottom-left-radius: $user-panel-radius;
    border-bottom-right-radius: $user-panel-radius;
    display: flex;
    flex-direction: column;
    position: absolute;

    left: 0;
    right: 0;

    padding: $m-sm;
    width: 100%;
    top: 100%;

    & > a {
      display: flex;
      align-items: center;
      font-size: $font-size-sm;
      letter-spacing: 0.28px;

      &:not(:nth-last-child(1)) {
        margin-bottom: $m-sm; }

      svg {
        margin-right: $m-xs; } } }
  &.-active {
    background: $color-black;
    box-shadow: 0px 3px 10px #1F1F1F; }

  @include media-breakpoint-only(xs) {
    min-width: 110px;
    .user__name,
    .chevron {
      display: none; }

    .user__image {
      margin-bottom: 0; }
    &.-active {
      .user__image {
        margin: 0 auto; } } } }
