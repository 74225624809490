%column {
  min-height: 1px;
  padding: {
    left: ($grid-gutter-width / 2);
    right: ($grid-gutter-width / 2); }
  position: relative;
  width: 100%; }

@mixin container {
  margin: {
    left: auto;
    right: auto; }
  max-width: $container;
  padding: {
    left: $grid-gutter-width;
    right: $grid-gutter-width; }
  width: 100%; }

@mixin container-fluid($gutter: $grid-gutter-width) {
  margin: {
    left: auto;
    right: auto; }
  padding: {
    left: $grid-gutter-width;
    right: $grid-gutter-width; }
  width: 100%; }

@mixin row($gutter: $grid-gutter-width) {
  display: flex;
  flex-wrap: wrap;

  @if $gutter != 0 {
    margin: {
      left: ceil(-$gutter / 2);
      right: floor(-$gutter / 2); } }
  @else {
    > * {
      padding: {
        left: 0;
        right: 0; } } } }

//----- columns --------------------------------------------------------------------------------------------------------
@mixin col {
  flex-basis: 0;
  flex-grow: 1;
  max-width: 100%; }

@mixin col-auto {
  flex: 0 0 auto;
  max-width: none;
  width: auto; }

//----- extra small columns --------------------------------------------------------------------------------------------
@mixin column($columns, $gutter: $grid-gutter-width) {
  @extend %column;
  flex: 0 0 percentage($columns / $grid-columns);
  max-width: percentage($columns / $grid-columns); }

@mixin offset($columns) {
  margin-left: percentage($columns / $grid-columns); }

@mixin order($columns) {
  order: $columns; }

//----- small columns --------------------------------------------------------------------------------------------------
@mixin column-sm($columns, $gutter: $grid-gutter-width) {
  @extend %column;
  @include media-breakpoint-up(sm) {
    width: percentage($columns / $grid-columns); } }

@mixin offset-sm($columns) {
  @include media-breakpoint-up(sm) {
    margin-left: percentage($columns / $grid-columns); } }

@mixin order-sm($columns) {
  @include media-breakpoint-up(sm) {
    order: $columns; } }

//----- medium columns -------------------------------------------------------------------------------------------------
@mixin column-md($columns, $gutter: $grid-gutter-width) {
  @extend %column;
  @include media-breakpoint-up(md) {
    flex: 0 0 percentage($columns / $grid-columns);
    max-width: percentage($columns / $grid-columns); } }

@mixin offset-md($columns) {
  @include media-breakpoint-up(md) {
    margin-left: percentage($columns / $grid-columns); } }

@mixin order-md($columns) {
  @include media-breakpoint-up(md) {
    order: $columns; } }

//----- large columns --------------------------------------------------------------------------------------------------
@mixin column-lg($columns, $gutter: $grid-gutter-width) {
  @extend %column;
  @include media-breakpoint-up(lg) {
    flex: 0 0 percentage($columns / $grid-columns);
    max-width: percentage($columns / $grid-columns); } }

@mixin offset-lg($columns) {
  @include media-breakpoint-up(lg) {
    margin-left: percentage($columns / $grid-columns); } }

@mixin order-lg($columns) {
  @include media-breakpoint-up(lg) {
    order: $columns; } }

//----- extra large columns --------------------------------------------------------------------------------------------
@mixin column-xl($columns, $gutter: $grid-gutter-width) {
  @extend %column;
  @include media-breakpoint-up(xl) {
    flex: 0 0 percentage($columns / $grid-columns);
    max-width: percentage($columns / $grid-columns); } }

@mixin offset-xl($columns) {
  @include media-breakpoint-up(xl) {
    margin-left: percentage($columns / $grid-columns); } }

@mixin order-xl($columns) {
  @include media-breakpoint-up(xl) {
    order: $columns; } }


