.empty-page {
  margin: 1em auto 3em;
  text-align: center;
  width: 100%;
  max-width: 500px;

  img {
    width: 100%; }

  h1 {
    margin: 1em 0; }

  p {
    font-size: 16px;
    font-weight: $font-weight-light;
    margin: .4em 0; }

  &__cta-wrapper {
    margin-top: 1em; } }
