.logo {
  margin: 0;

  &__link {
    display: block;
    margin-top: 32px;
    margin-left: 40px;
    color: white;
    font-size: 32px;
    font-weight: 600;
    text-decoration: none;

    width: 199px;

    &.-selleo-logo {
      margin-right: 40px;
      display: flex;
      justify-content: center;

      width: 199px;

      img {
        height: 38px; } } }

  @include media-breakpoint-down(lg) {
    display: none; } }
