.btn {
  @include transition(background-color, color);
  border: none {
    radius: $border-radius-sm; }
  cursor: pointer;
  display: inline-block;
  font: {
    family: $font-stack-body;
    size: $font-size-base;
    weight: $font-weight-regular; }
  line-height: $line-height-base;
  padding: $m-xs $m-base;
  text-decoration: none;

  //----- color versions -----------------------------------------------------------------------------------------------
  &.-primary {
    background-color: $color-primary;
    color: $color-white; }

  &.-success {
    background-color: $color-success;
    color: $color-white; }

  &.-link {
    color: $color-primary;

    &:hover {
      background-color: $color-gray-400; } }

  &.-outline {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px $color-gray-500;
    color: $color-gray-600;

    &:hover {
      background-color: $color-gray-400; } }

  &.-outline-primary {
    background-color: transparent;
    box-shadow: inset 0 0 0 1px $color-primary;
    color: $color-gray-600;

    &:hover {
      background-color: $color-gray-400; } }

  //----- sizes --------------------------------------------------------------------------------------------------------
  &.-lg {
    font: {
      size: $font-size-base;
      weight: $font-weight-bold; }
    letter-spacing: 1.5px;
    padding: $m-base;
    width: 100%; }

  &.-sm {
    font-size: $font-size-xs;
    line-height: $m-base;
    padding: $m-xs $m-sm; } }
