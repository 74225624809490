.buttons {
  display: flex;
  justify-content: center;
  margin: {
    bottom: $m-sm * 4;
    top: $m-sm * 7; }

  &__button-primary {
    background-color: $color-primary;
    border-radius: $border-radius-sm;
    border: none;
    color: $color-dark;
    display: inline-block;
    font-size: $font-size-m;
    font-weight: $font-weight-500;
    height: $m-base * 2;
    margin-left: $m-sm * 5;
    outline: none;
    padding: $m-xs;
    text-align: center;
    text-transform: uppercase;
    width: $m-base * 7;
    transition: $transition-duration $transition-function;
    @include transition(background-color);

    &:hover, &:focus {
      background-color: #E0B228; }

    &:disabled {
      background: none;
      border-radius: $m-xs;
      border: 1px solid $color-gray;
      color: $color-gray;
      font-size: $m-sm;
      font-weight: $font-weight-500;
      height: $m-lg;
      outline: none;
      text-transform: uppercase;
      width: $m-sm * 7;
      opacity: 0.4;
      cursor: not-allowed; } }

  &__button-cancel {
    background: none;
    border-radius: $border-radius-sm;
    border: 1px solid $color-gray;
    color: $color-gray;
    font-size: $font-size-m;
    font-weight: $font-weight-500;
    height: $m-lg;
    outline: none;
    text-transform: uppercase;
    width: $m-sm * 7;
    @include transition(color, border-color);

    &:hover, &:focus {
      border-color: $color-white;
      color: $color-white; } }

  &__button-logout {
    background: none;
    border: none;
    color: white;
    cursor: pointer;
    padding: 0;
    text-align: left;
    text-decoration: none;

    svg {
      height: $m-sm;
      width: $m-sm; }

    .text-logout {
      font-size: $font-size-m; } } }
