.checkboxes {
  margin-top: $m-xs * 5;

  &__input:focus ~ .checkboxes__checkmark {
    border-color: $color-primary; }

  &__single-checkbox {
    -moz-user-select: none;
    -ms-user-select: none;
    -webkit-user-select: none;
    color: $color-gray;
    cursor: pointer;
    display: block;
    font: {
      size: $font-size-m;
      weight: $font-weight-500; }
    font-family: $font-family;
    margin-bottom: $m-sm;
    padding-left: $m-xs * 5;
    position: relative;
    user-select: none;

    input:checked ~ .checkboxes__checkmark:after {
      display: block; }

    &.-active {
      color: $color-white; }

    &.-darken {
      opacity: 0.5; }

    label {
      align-content: center;
      color: $color-gray;
      cursor: pointer;
      display: flex;
      font-weight: $font-weight-600; }

    input {
      cursor: pointer;
      height: 0;
      opacity: 0;
      position: absolute;
      width: 0; } }

  &__checkmark {
    border: 1px solid $color-gray;
    border-radius: $border-radius-sm / 2;
    cursor: pointer;
    height: $m-base;
    left: 0;
    position: absolute;
    top: 0px;
    width: $m-base;

    svg {
      display: none; }

    &.-active {

      svg {
        display: block;
        margin: auto;

        path {
          fill: $color-primary; } } } } }
