.user {
  &__image {
    align-items: flex-end;
    border-radius: 50%;
    display: flex;
    height: $m-xs * 5;
    justify-content: center;
    margin-right: $m-sm;
    overflow: hidden;
    width: $m-xs * 5;

    .-background-light {
      background-color: $color-background; }

    .-background-dark {
      background-color: $color-black; } }

  &__name {
    font-size: $font-size-m;
    font-weight: $font-weight-600;
    margin: auto; }

  &__icon {
    height: 80%; } }

@include media-breakpoint-only(xs) {
  .user__image {
    margin-bottom: $m-xs;
    margin-right: 0; } }
