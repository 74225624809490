.post-wrapper {
  text-decoration: none; }

.post {
  background: $color-black;
  border-radius: $border-radius-sm;
  margin-bottom: $m-lg;
  padding: $m-sm * 2;
  position: relative;
  &.-interactive {
    cursor: pointer; }
  &__skeleton {
    &-avatar {
      border-radius: 50%;
      margin-right: 16px;
      margin-bottom: 12px;
      height: 40px;
      width: 40px; }

    &-details {
      min-width: 200px;
      height: 40px; }

    &-title {
      min-width: 200px;
      height: 48px; }

    &-body {
      margin-top: 20px;
      min-width: 200px;
      min-height: 408px; } }

  &:hover {
    @include transition(background-color);
    background-color: $color-dark; }

  &__my-posts-header {
    margin: $m-lg 0; }

  &__link {
    color: $color-white;
    text-decoration: none; }

  &__body {
    color: $color-white;
    margin-bottom: $m-lg;
    min-height: $m-2xl;

    p {
      font-size: $font-size-lg;
      font-weight: $font-weight-light;
      line-height: normal;
      word-break: break-word; }

    code {
      padding: $m-xs/2;
      color: $color-code-markdown;
      background-color: $color-code-background;
      font-family: $font-family-code-markdown; }

    &.-preview {
      margin-bottom: $m-sm;
      flex-grow: 1; } }

  &__categories {
    align-items: baseline;
    color: $color-gray;
    display: flex;
    flex-basis: 100%;
    flex-wrap: wrap;
    margin-bottom: $m-sm; }


  &__author {
    color: $color-white;
    font-weight: $font-weight-500; }

  &__date {
    margin-top: $m-xs / 2; }

  &__date,
  &__is-public {
    color: $color-gray;
    font-weight: $font-weight-light; }

  &__is-public {
    font-size: $font-size-sm; }

  &__details {
    display: flex;
    flex-direction: column;
    font-size: $font-size-base;

    .user__image {
      margin-bottom: $m-sm; } }

  &__footer {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    p {
      font-size: $font-size-base; } }

  &__header {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin-bottom: $m-sm * 2; }

  &__hr {
    border: 1px solid $color-background;
    margin-top: $m-base; }

  &__reactions {
    align-self: baseline;
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    max-width: 460px;
    width: 100%; }

  &__share-button {
    @include transition(color);
    align-items: center;
    color: gray;
    cursor: pointer;
    display: flex;
    font-size: $font-size-base;
    font-weight: $font-weight-600;
    justify-content: space-around;
    width: auto;

    svg {
      margin-right: $m-xs / 4; }

    &:hover {
      color: $color-white;
      @include transition(fill);

      & svg {
        fill: $color-white; } } }

  &__share-text {
    margin-left: $m-xs; }

  &__separator {
    align-items: center;
    display: flex;
    justify-content: space-between;

    &:after,
    &:before {
      border: 1px solid $color-gray;
      content: "";
      width: 45%; }

    div {
      border: 2px solid $color-gray;
      border-radius: $border-radius-sm;
      height: $m-xs * 5;
      margin: $m-base $m-lg;
      position: relative;
      width: $m-lg;

      img {
        left: 50%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        width: $m-base; } } }

  &__single-category {
    align-items: center;
    border: 1px solid $color-gray;
    border-radius: $border-radius-base;
    color: $color-gray;
    cursor: pointer;
    display: flex;
    font-size: $font-size-sm;
    margin-bottom: $m-sm;
    margin-right: $m-base;
    padding: $m-xs $m-base;
    text-transform: uppercase;

    &:hover {
      background: rgba($color-gray, 0.1 ); } }

  &__more-categories {
    cursor: pointer;
    opacity: 0.6;
    position: relative;
    @include transition(opacity);

    &:hover {
      opacity: 1; } }

  &__single-reaction {
    @include transition(width);
    align-items: center;
    color: $color-gray;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    width: $m-lg;

    &:hover {

      .reaction-number {
        color: $color-white;
        @include transition(color); }

      .like {
        transform: scale(1.1);

        path {
          stroke: $color-white; } }

      .funny,
      .love,
      .surprised {
        transform: scale(1.1);

        path {
          fill: $color-white; } } }

    &:not(:nth-last-child(1)) {
      margin-right: $m-sm; }

    div {
      margin-left: $m-xs; }

    svg,
    div {
      @include transition(color, transform);
      color: $color-gray; }

    svg {
      width: $m-base;

      path {
        @include transition(fill); } }

    .like {
      &.-fill {
        path {
          stroke: $color-icon-like; } } }

    .funny {
      &.-fill {
        path {
          fill: $color-icon-funny; } } }

    .love {
      &.-fill {
        path {
          fill: $color-icon-love; } } }

    .surprised {
      &.-fill {
        path {
          fill: $color-icon-surprised; } } } }

  &__text-details {
    color: $color-white;
    font-weight: $font-weight-600; }

  &__owner {
    .animation {
      position: relative;

      &:before,
      &:after {
        @include transition(color, width, opacity);
        background-color: $color-primary;
        bottom: 0;
        content: "";
        height: 1px;
        margin: 1px 0 0;
        opacity: 0;
        position: absolute;
        width: 0; }

      &.animation {
        &:before,
        &:after {
          left: 0; } } }

    &:hover {
      cursor: pointer;

      .animation {
        &:before,
        &:after {
          opacity: 1;
          width: 100%; } } } }

  &__title {
    color: $color-white;
    display: block;
    font-size: $font-size-post-title;
    font-weight: $font-weight-bold;
    line-height: $line-height-lg;
    margin-bottom: $m-sm;
    text-decoration: none;
    word-break: break-word; }

  &.-preview {
    display: flex;
    flex-basis: 55%;
    flex-direction: column;
    margin: 0;
    min-width: 0; }

  &__user-menu {
    display: flex;
    justify-content: space-between;
    margin: $m-base auto 0px;

    &.-owner-view {
      justify-content: center;

      button {
        width: $m-2xl * 2; } } }

  @include media-breakpoint-only(xs) {
    &__user-menu {
      max-width: 250px;

      button {
        width: $m-2xl; } } }

  @include media-breakpoint-up(sm) {
    &__user-menu {
      max-width: 370px; }

    &__reactions {
      justify-content: space-between; }

    &__header {
      align-items: center; }

    &__details {
      align-items: center;
      flex-direction: row;

      .user__image {
        margin-bottom: 0px;
        margin-right: $m-sm;
        @include transition(box-shadow);

        &:hover {
          box-shadow: 0 0 0 1.5px $color-primary; } } } }

  @include media-breakpoint-up(lg) {
    &__footer {
      flex-direction: row;
      margin-bottom: -$m-sm; }

    &__categories {
      margin-bottom: 0;
      margin-right: $m-lg;
      padding-top: $m-base; }

    &__user-menu {
      margin: $m-base 0px 0px auto;

      &.-owner-view {
        justify-content: flex-end; } }

    &__reactions {
      align-self: center;
      max-width: 285px; }

    &__separator {
      flex-direction: column;

      &:after,
      &:before {
        height: 50%;
        width: unset; }

      div {
        height: $m-lg;
        transform: rotate(-90deg); } } } }
