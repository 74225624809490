.nothing-found-wrapper {
  margin: 4em auto 3em;
  text-align: center;
  width: 100%;
  max-width: 500px;
  img {
    width: 100%;
    margin: 0em auto 3em; }
  p {
    margin: 0;
    font-size: 16px;
    font-weight: $font-weight-light;
    b {
      font-size: 18px; } } }
