.search-box {
  position: relative;
  width: 80%;
  &__input {
    background: $color-background;
    border-radius: 0 10px 10px 0;
    border: none;
    color: white;
    font-size: 16px;
    height: 48px;
    opacity: 1;
    outline: none;
    width: 230px; }

  &__icon {
    position: absolute;
    right: -8px;
    bottom: 0px;

    &.-cancel {
      cursor: pointer;
      height: 100%;
      width: 50px;
      display: flex;
      align-items: center;
      justify-content: center;

      svg {
        transform: scale(1.5); } } }

  &::before {
    background: $color-background;
    content: '';
    left: -65px;
    width: 65px;
    position: absolute;
    right: 0;
    top: 0;
    height: 48px; }

  &::placeholder {
    font-size: 16px;
    font-weight: 600;
    font-family: $font-family;
    letter-spacing: 0.32px; } }
