.pagination {
    &__wrapper {
        display: flex;
        justify-content: center; }
    &__page {
        padding: 10px 18px;
        color: $color-white;
        border-radius: 4px;
        transition: color .3s;
        &.-active {
            background-color: $color-black;
            color: $color-primary; }
        &:hover {
            color: $color-primary; }
        &.-dot {
            color: $color-white;
            cursor: auto; } } }
